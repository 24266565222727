<template>
	<div class="list-sites">
		<div class="list-header">
			<h3>Tous les sites :</h3>
			<div id="ajout">
				<input type="text" id="creerSiteValue" placeholder="Nom du nouveau site" required minlength="4" maxlength="50" size="50" />
				<button @click="createSite()" class="blue btn" type="button">Créer</button>
				<div id="resultat"></div>
			</div>
		</div>
		<ul id="liste">
			<li v-for="(site, index) in liste" v-bind:key="index">
				<div class="nomSite">{{ site.title }}</div>
				<div class="actions-container">
					<div>
						<a :href="`${$DOCUMENTROOT_URL}/${site.data.sortedName}/`" target="_blank" class="listeVoir btn blue">Voir</a>
						<router-link class="btn blue listeEditer" :to="{ name: 'EditionSite', params: { sortedName: site.data.sortedName, id: site.data.id } }">Éditer</router-link>
					</div>
					<button @click="deleteSite(site.data.id)" class="btn red listeSupprimer">Supprimer</button>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
const axios = require('axios');
import { Checkbox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

export default {
	name: 'ListSites',
	components: {
		'el-checkbox': Checkbox
	},
	data: function () {
		return {
			liste: [],
			nomNouveauSite: '',
			resultat: '',
			checked: false
		};
	},
	methods: {
		createSite: function name() {
			let self = this;

			const format = /[`@#$%^&*+\=\[\]{};':"\\|,.<>\/~]/;

			let nomDusite = document.getElementById('creerSiteValue').value;
			if (!nomDusite) return;

			// Check if site name contains special characters 
			if (format.test(nomDusite)) {
				this.$message({
					type: 'warning',
					message: 'Le nom du site ne doit pas contenir de caractères spéciaux !'
				});
				return;
			}
			if (nomDusite === 'commun') {
				this.$message({
					type: 'warning',
					message: 'Le nom du site ne peut pas être "commun"'
				});
				return;
			}

			axios
				.post(`${this.$API_URL}/sites/`, { name: nomDusite })
				.then(function (response) {
					// handle success
					self.fetchSites();

				})
				.catch((error) => {
					// handle error
					const { response } = error;
					const { request, ...errorObject } = response; // take everything but 'request'

					errorObject.status === 409 ?
						this.$message({
							type: 'warning',
							message: 'Le site existe déjà !'
						}) :
						this.$message({
							type: 'warning',
							message: 'Erreur lors de la création...'
						})
				})
		},
		deleteSite: function (id) {
			let self = this;

			this.$confirm('Êtes-vous sûr de vouloir supprimer ce site ?', 'Warning', {
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				type: 'warning'
			}).then(() => {
				axios
					.delete(`${this.$API_URL}/sites/${id}/`)
					.then((response) => {
						// handle success
						self.fetchSites();
						this.$message({
							type: 'success',
							message: 'Le site a été supprimé.'
						});
						return;

					})
					.catch((error) => {
						// handle error
						const { response } = error;
						const { request, ...errorObject } = response; // take everything but 'request'

						errorObject.status === 422 ?
							this.$message({
								type: 'error',
								message: 'Le site est utilisé dans le portail.'
							}) :
							this.$message({
								type: 'warning',
								message: 'Erreur lors de la suppression...'
							})
					})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'La suppression a été annulée.'
				});
			});

		},
		fetchSites: function () {
			let self = this;
			axios
				.get(`${this.$API_URL}/sites/`)
				.then(function (response) {
					// handle success
					self.liste = response.data;
				})
				.catch(function (error) {
					// handle error
					this.$message({
						type: 'error',
						message: 'Vueillez vous reconnecter'
					});
				})
		},
	},
	created: function () {
		this.fetchSites();
	},
};
</script>

<style lang="scss" scoped>
.list-sites {
	padding: 2rem 4rem;

	margin: 0 auto;
	width: 100%;
	max-width: 1000px;

	h3 {
		text-align: left;
		padding: 0px;
		margin: 0px;
		margin-bottom: 0px;
		font-weight: 600;
	}
}

.list-header {
	/*background:#00f;*/
	margin: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 15px;
	padding-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	#ajout {
		display: flex;
		align-items: center;
	}

	#ajout .listeCreer {
		padding: 6px 12px;
		font-weight: 600;
		font-size: 16px;

		input {
			height: 40px;
		}
	}
}

ul#liste li {
	padding: 15px;
	margin: 22px 0px 0px 0px;
	background: #eee;
	border-radius: 12px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
}

ul#liste li .actions-container {
	display: flex;
	justify-content: space-between;
}

ul#liste .nomSite {
	font-weight: 600;
	font-size: 18px;
}
</style>
